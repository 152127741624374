<template>

  <div v-if="pessoa_show != null" class="selecao-detalhes">

    <div class="p-grid">
      <div class="p-field p-md-3" v-if="pessoa_show.tipo == 'PF'">
        <label for="cpf">CPF:</label>
        <span>{{ pessoa_show.cpfcnpj }}</span>
      </div>

      <div class="p-field p-md-3" v-else>
        <label for="cpf">CNPJ:</label>
        <span>{{ pessoa_show.cpfcnpj }}</span>
      </div>

      <div class="p-field p-md-9">
        <label for="nome">
          <span v-if="pessoa_show.tipo == 'PF'">Nome Completo</span>
          <span v-else>Razão Social:</span>
        </label>
        <span>{{ pessoa_show.nome }}</span>
      </div>

      <div class="p-field p-md-3" v-if="pessoa_show.tipo == 'PF'">
        <label for="rg">RG:</label>
        <span>{{ pessoa_show.rg }}</span>
      </div>

      <div class="p-field p-md-3" v-if="pessoa_show.tipo == 'PF'">
        <label for="rg_orgao">Órgão Emissor</label>
        <span>{{ pessoa_show.rg_orgao }}</span>
      </div>

      <div class="p-field p-md-3" v-if="pessoa_show.tipo == 'PF'">
        <label for="rg_expedicao">Expedição</label>
        <span>{{ pessoa_show.rg_expedicao }}</span>
      </div>

      <div class="p-field p-md-3">
        <label for="nascimento">
          <span v-if="pessoa_show.tipo == 'PF'">Nascimento</span>
          <span v-else>Fundação</span>
        </label>
        <span>{{ $utils.formatDateToBr(pessoa_show.nascimento) }}</span>
      </div>

      <div :class="
        pessoa_show.tipo == 'PF' ? 'p-field p-col-12' : 'p-field p-col-9'
      ">
        <label for="email">E-Mail:</label>
        <span>{{ pessoa_show.email }}</span>
      </div>

      <div class="p-field p-md-6" v-if="pessoa_show.tipo == 'PF'">
        <label for="estado_civil">Estado Civil:</label>
        <span>{{ pessoa_show.estado_civil_item.nome }}</span>
      </div>

      <div class="p-field p-md-6" v-if="pessoa_show.tipo == 'PF'">
        <label for="sexo">Sexo:</label>
        <span>{{ pessoa_show.sexo }}</span>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-md-12" v-if="pessoa_show.tipo == 'PJ'">
        <h6 class="p-mb-0">
          <i class="pi pi-map-marker"></i> Endereço da Empresa
        </h6>
      </div>

      <div class="p-field p-md-2">
        <label for="residencia_cep">CEP:</label>
        <span>{{ pessoa_show.cep }}</span>
      </div>

      <div class="p-field p-md-8">
        <label for="residencia_logradouro">Endereço:</label>
        <span>{{ pessoa_show.logradouro }}</span>
      </div>

      <div class="p-field p-md-2">
        <label for="residencia_numero">Número:</label>
        <span>{{ pessoa_show.numero }}</span>
      </div>

      <div class="p-field p-md-12">
        <label for="residencia_complemento">Complemento:</label>
        <span>{{ pessoa_show.complemento }}</span>
      </div>

      <div class="p-field p-md-5">
        <label for="residencia_bairro">Bairro</label>
        <span>{{ pessoa_show.bairro }}</span>
      </div>

      <div class="p-field p-md-5">
        <label for="residencia_cidade">Cidade</label>
        <span>{{ pessoa_show.cidade }}</span>
      </div>

      <div class="p-field p-md-2">
        <label for="residencia_uf">UF</label>
        <span>{{ pessoa_show.uf }}</span>
      </div>
    </div>

    <div class="p-grid" v-if="typeof pessoa_show.contatos != 'undefined' && pessoa_show.contatos.length">
      <div class="p-col-12">
        <h6 class="p-mb-0"><i class="pi pi-phone"></i> Telefones</h6>
      </div>

      <div class="p-col-12 p-field">
        <DataTable sortMode="single" :value="pessoa_show.contatos">
          <Column field="nome" headerClass="colNome" header="Observações">
            <template #body="slotProps">
              {{
                  slotProps.data.nome ? slotProps.data.nome : "Nenhuma"
              }}
            </template>
          </Column>
          <Column field="telefone" headerClass="colTelefone" headerStyle="width:145px" header="Telefone">
            <template #body="slotProps">
              {{ $utils.formatPhone(slotProps.data.telefone) }}
            </template>
          </Column>
          <Column field="tipo" headerClass="colTipo" headerStyle="width:67px" header="Tipo"></Column>
          <Column field="e_whatsapp" headerClass="colWhatsapp" headerStyle="width:65px" header="Whatsapp?">
            <template #body="slotProps">
              {{ slotProps.data.e_whatsapp ? "Sim" : "Não" }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <div class="p-grid" v-if="pessoa_show.tipo == 'PJ'">
      <div class="p-col-12">
        <h6 class="p-mb-0">
          <i class="pi pi-users"></i> Sócios Representantes
        </h6>
      </div>

      <div class="p-col-12 p-field" v-if="pessoa_show.socios.length > 0">
        <DataTable sortMode="single" :value="pessoa_show.socios">
          <Column field="socio_item.cpfcnpj" headerClass="colCPF" header="CPF"></Column>
          <Column field="socio_item.nome" headerClass="colNome" header="Nome"></Column>
        </DataTable>
      </div>

    </div>

    <div class="center-loading" v-if="loading>0">
      <ProgressSpinner />
    </div>
  
  </div>

</template>
<script>
export default {
  props: [
    "pessoa"
  ],
  emits: [
  ],
  data() {
    return {
      // 1. Controle de apresentação
      loading: 1,
      // 2. Dados de apresentação
      pessoa_show: null
    }
  },
  mounted() {

    if(typeof this.pessoa == "object" && this.pessoa != null) {
      this.pessoa_show = this.pessoa;
      this.loading = 0;
    } else if(typeof this.pessoa != "undefined") {
      this.carregarObjeto();
    }

  },
  methods: {

    async carregarObjeto() {

      const response = await this.$api.get("/pessoas/" + this.pessoa);
      const dados = response.data;
      if (dados.success) {
        this.pessoa_show = dados.data;
      }

      this.loading--;

    }

  }
}
</script>
<style lang="scss" scoped>

.selecao-detalhes {
  margin-top: 1px;
}

.selecao-detalhes .p-field {
  border: 1px solid #ccc;
  margin: 0;
  padding: 0.3rem 0.4rem;
  margin-top: -1px;
}

.selecao-detalhes .p-field span {
  display: block;
}

.selecao-detalhes .p-field label {
  font-weight: 500;
  margin-bottom: 4px;
}

.selecao-detalhes .p-grid {
  margin: 0;
}

.selecao-detalhes th, .selecao-detalhes td {
  background: none !important;
  padding: 0.5rem !important;
}

</style>