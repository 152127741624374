<template>
  <div>
    <Card class="shadow-none">
      <template v-slot:title>
        <h4>Pretendentes:</h4>
      </template>
      <template v-slot:subtitle> </template>
      <template v-slot:content>
        <div class="p-grid">
          <div class="p-col-12 p-md-4">
            <AutoComplete
              v-model="pessoaSelecionada"
              :suggestions="pessoaResultados"
              @complete="filtrarAutoComplete($event)"
              field="nome"
              placeholder="Informe o nome da pessoa"
              :class="
                fieldsError.some((v) => v === 'pretendentes')
                  ? 'p-invalid'
                  : ''
              "
            />
          </div>
          <div class="p-col-12 p-md-8">
            <Button
              label="cadastrar pretendente"
              class="p-button-warning"
              @click="pessoa_edit = null; display_pessoa_edit = true"
            />
          </div>
        </div>
        <div class="p-grid p-mt-2">
          <div class="p-col-12">
            <DataTable sortMode="single" :value="pretendentes" style="max-width: 880px">
              <Column field="nome" headerClass="colNome" header="Nome">
              </Column>
              <Column
                field="residir"
                headerClass="colResidir"
                headerStyle="width:130px"
                header="Vai residir?"
              >
                <template #body="slotProps">
                  {{ slotProps.data.residir ? "Sim" : "Não" }}
                </template>
              </Column>

              <Column headerStyle="width:150px">
                <template #body="slotProps">
                  <Button 
                    v-tooltip.top="'Ver Detalhes'"
                    icon="pi pi-eye" 
                    class="p-mb-2 p-button-info p-mr-2"
                    @click="pessoa_view = slotProps.data.id; display_pessoa_view = true"
                  />
                  <Button 
                    v-tooltip.top="'Editar Cadastro'"
                    icon="pi pi-pencil" 
                    class="p-mb-2 p-button-warning p-mr-2"
                    v-if="slotProps.data.conjuge == 0"
                    @click="pessoa_edit = slotProps.data; display_pessoa_edit = true"
                  />
                  <Button
                    v-tooltip.top="'Remover'"
                    icon="pi pi-trash"
                    class="p-mr-2 p-mb-2 p-button-danger"
                    v-if="slotProps.data.conjuge == 0"
                    @click="removerPretendente($event, slotProps.index)"
                  ></Button>
                </template>
              </Column>
              <template #empty> Nenhum pretendente incluído. </template>
            </DataTable>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="p-grid p-nogutter p-justify-between">
          <Button label="Voltar" @click="prevPage()" icon="pi pi-angle-left" />
          <Button
            label="Próximo"
            @click="nextPage()"
            icon="pi pi-angle-right"
            iconPos="right"
          />
        </div>
      </template>
    </Card>
  </div>

  <Dialog v-model:visible="display_pessoa_edit" style="max-width: 98%; width: 1200px;" :modal="true">
    <PessoasEdit @created="pessoaCadastrada" @updated="pessoaAtualizada" :pessoa="pessoa_edit" :imobiliaria="imobiliaria" />
  </Dialog>

  <Dialog v-model:visible="display_pessoa_view" style="width: 1000px; max-width: 98%;" :modal="true">
    <PessoasView :pessoa="pessoa_view" />
  </Dialog>

  <Dialog :visible="typeof pessoaSelecionada == 'object' && pessoaSelecionada != null && ira_residir == -1" style="width: 350px;" :modal="true" :closable="false">
    <template #header>
      <div class="p-text-bold">O(A) Pretendente {{ pessoaSelecionada.nome.split(" ")[0] }}:</div>
    </template>

    <div class="p-text-center">
      <SelectButton
        id="ira_residir"
        v-model="ira_residir"
        :options="[
          { nome: 'Vai residir', id: 1 },
          { nome: 'Não vai residir', id: 0 },
        ]"
        optionLabel="nome"
        optionValue="id"
        style="display: inline-block"
      />
    </div>

  </Dialog>

  <ConfirmPopup></ConfirmPopup>

</template>

<script>
import PessoasEdit from "../../pessoas/PessoasEdit.vue";
import PessoasView from "../../pessoas/PessoasView.vue";

export default {
  props: {
    errorData: Array,
    processo: Object,
    imobiliaria: Number
  },
  emits: [
    "prev-page",
    "next-page",
    "complete"
  ],
  data() {
    return {
      display_pessoa_edit: false,
      display_pessoa_view: false,
      fieldsError: [],

      // AUTOCOMPLETE OBRIGATORIOS: //
      pessoaSelecionada: null,
      pessoaResultados: [],
      // FIM AUTOCOMPLETE //

      pretendentes: [],

      pessoa_edit: null,
      pessoa_view: null,

      ira_residir: -1
    };
  },
  mounted() {
    this.fieldsError = this.errorData;

    if(this.processo != null) {

      for(const p of this.processo.pretendentes) {

        const pretendente = {
          id: p.pessoa_item.original,
          nome: p.pessoa_item.nome,
          residir: p.e_morador,
          conjuge: 0
        };

        const idx = this.pretendentes.findIndex((obj) => p.pessoa_item.conjuge_item != null && obj.id == p.pessoa_item.conjuge_item.id);

        if(idx != -1)
          pretendente.conjuge = p.pessoa_item.conjuge_item.id;

        this.pretendentes.push(pretendente);

      }

    }

  },
  watch: {

    pretendentes: {
      handler: function(pretendentes) {
        console.log(pretendentes);
      },
      deep: true,
    }, 

    errorData: function() {
      this.fieldsError = this.errorData;
    },

    ira_residir: function(val) {
      if(val === 1 || val === 0) {
        this.adicionarPretendente();
      }
    },   

  },
  methods: {

    pessoaCadastrada(pessoa) {

      if(typeof pessoa == "object" && pessoa != null) {
        this.pessoaResultados = [pessoa];
        this.pessoaSelecionada = pessoa;
      }

      this.display_pessoa_edit = false;
      this.pessoa_edit = null;

    },

    pessoaAtualizada(pessoa) {

      if(typeof pessoa == "object" && pessoa != null) {

        const p = this.pretendentes.findIndex((obj) => obj.id == pessoa.id);
        this.pretendentes[p].nome = pessoa.nome;

        const pc = this.pretendentes.findIndex((obj) => obj.conjuge == pessoa.id);
        if(pc != -1) {
          this.pretendentes.splice(pc, 1);
        }

        if(typeof pessoa.conjuge_item == "object" && typeof pessoa.conjuge_item.id != "undefined") {

          this.pretendentes.push({
            id: pessoa.conjuge_item.id,
            nome: pessoa.conjuge_item.nome,
            residir: this.ira_residir,
            conjuge: pessoa.id
          });

        }

      }

      this.display_pessoa_edit = false;
      this.pessoa_edit = null;

    },

    nextPage() {
      if (this.validateForm()) {
        let formData = {
          pretendentes: this.pretendentes,
        };

        this.$emit("next-page", { formData: formData, pageIndex: 1 });
      }
    },
  
    prevPage() {
      this.$emit("prev-page", { pageIndex: 1 });
    },
  
    validateForm() {
      this.fieldsError = [];

      if (!this.pretendentes.length) {
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "É necessário adicionar no mínimo um pretendente!",
          life: 5000,
        });
        return false;
      }

      return true;
    },

    filtrarAutoComplete: function (event) {
      const self = this;

      const q = event.query.toLowerCase();

      this.$api
        .get(
          "/pessoas?fields=nome,cpfcnpj&relationships=conjuge_item:nome&filters=" +
            '{"str":["' +
            q +
            '"],"tipo":["PF"], "imobiliaria": [' + this.imobiliaria + ']}'
        )
        .then(function (response) {
          const dados = response.data;
          if (dados.success) {
            self.pessoaResultados = dados.data;
            for(const r of self.pessoaResultados)
              r.nome += " - " + self.$utils.formatCpf(r.cpfcnpj);
          }
        });
    },

    adicionarPretendente() {
      if (this.pessoaSelecionada == null) return;

      this.pretendentes.push({
        id: this.pessoaSelecionada.id,
        nome: this.pessoaSelecionada.nome,
        residir: this.ira_residir,
        conjuge: 0
      });

      if (typeof this.pessoaSelecionada.conjuge_item != "undefined" && this.pessoaSelecionada.conjuge_item != null) {
        this.pretendentes.push({
          id: this.pessoaSelecionada.conjuge_item.id,
          nome: this.pessoaSelecionada.conjuge_item.nome,
          residir: this.ira_residir,
          conjuge: this.pessoaSelecionada.id
        });
      }

      this.pessoaSelecionada = null;
      this.ira_residir = -1;      
    },

    removerPretendente(event, index) {
      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: "Tem certeza que deseja excluir?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {

          const idConjuge = self.pretendentes[index].id;

          self.pretendentes.splice(index, 1);

          const idx = self.pretendentes.findIndex((obj) => obj.conjuge == idConjuge);

          if(idx != -1) {
            self.pretendentes.splice(idx, 1);
          }

        },
      });
    },
  
  },
  components: {
    PessoasEdit,
    PessoasView
  },
};
</script>

<style scoped>
:deep(.p-autocomplete) {
  display: block;
}

:deep(.p-autocomplete-input) {
  width: 100%;
}

:deep(.p-selectbutton .p-button.p-highlight),
:deep(.p-togglebutton.p-button.p-highlight) {
  background: #6d6c6c;
  border-color: #666;
}
</style>